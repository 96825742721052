import "./src/styles/index.css"

// Import Animate on Scroll
import AOS from 'aos';



// Initialise Animate on Scroll

AOS.init({
  duration: 700,
  offset: 150,
  throttleDelay: 9,
});